<template>
  <div :class="type">
    <shared-index
      :columns="columns"
      :actions="actions"
      :type="type"
      :titles="titles"
      :header-actions="headerActions"
      :filterable="true"
    />
  </div>
</template>

<script>
import SharedIndex from '@/components/shared/index'

export default {
  name: 'Country',
  components: {
    SharedIndex
  },
  data() {
    return {
      type: 'country',
      titles: [{ label: 'Đất nước', value: 'country' }],
      columns: ['stt', 'avatar', 'name', 'created_at', 'language', 'actions'],
      actions: ['edit', 'delete'],
      headerActions: ['add', 'reset', 'synchronize']
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped></style>
